<template>
  <div>
    <ModalAddAccount
      v-if="modalType === modalTypes.modalAdd"
      :showModal="modalType === modalTypes.modalAdd"
      :onClickCancel="hideModal"
      @reloadList="getAccounts"
    />
    <ModalEditAccountRights
      v-if="modalType === modalTypes.modalAccountRights"
      :showModal="modalType === modalTypes.modalAccountRights"
      :onClickCancel="hideModal"
      :account="selectedAccount"
      @reloadList="getAccounts"
    />
    <ModalDeleteAccount
      v-if="modalType === modalTypes.modalDelete"
      :showModal="modalType === modalTypes.modalDelete"
      :onClickCancel="hideModal"
      :account="selectedAccount"
      @reloadList="getAccounts"
    />
    <DrawerAccountSummary
      v-if="drawerType === drawerTypes.drawerSummary"
      :showDrawer="drawerType === drawerTypes.drawerSummary"
      :onClickClose="hideDrawer"
      :account="selectedAccount"
    />

    <div>
      <div
        class="actionBar has-background-white-ter has-padding has-margin-bottom"
      >
        <div class="level is-mobile">
          <div class="level-left"></div>
          <div class="level-right">
            <a class="has-icon" @click="openModal(modalTypes.modalAdd)">
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'plus']" />
              </span>
              <span
                v-html="$t('Accounts.ManageAccounts.Link_AddAccount')"
              ></span>
            </a>
          </div>
        </div>
      </div>

      <table class="table is-fullwidth is-striped is-hoverable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Status</th>
            <th>&nbsp;</th>
          </tr>
        </thead>

        <transition
          name="fade"
          mode="out-in"
          :duration="{ enter: 200, leave: 200 }"
        >
          <tbody v-if="isLoading" key="loading">
            <tr v-for="i in itemsPerPage" :key="'item_' + i">
              <td>
                <text-placeholder
                  class="is-inline-flex"
                  :isLoading="true"
                  :fixSize="240"
                  :numberOfItems="1"
                  placeholderItemHeight="17px"
                />
              </td>
              <td>
                <text-placeholder
                  class="is-inline-flex"
                  :isLoading="true"
                  :fixSize="240"
                  :numberOfItems="1"
                  placeholderItemHeight="17px"
                />
              </td>
              <td>
                <text-placeholder
                  class="is-inline-flex has-text-right"
                  :isLoading="true"
                  :fixSize="80"
                  :numberOfItems="1"
                  placeholderItemHeight="17px"
                />
              </td>
              <td>
                <text-placeholder
                  style="flex-wrap: nowrap;"
                  class="is-flex"
                  :isLoading="true"
                  :fixSize="18"
                  :numberOfItems="3"
                  placeholderItemHeight="17px"
                />
              </td>
            </tr>
          </tbody>
          <tbody v-else key="list">
            <tr v-for="account in accounts" :key="`a${account.Id}`">
              <td>
                <span v-html="account.FullName"></span>
              </td>
              <td>
                <span v-html="account.Email"></span>
              </td>
              <td>
                <span v-html="account.Status"></span>
              </td>
              <td>
                <div class="field is-grouped">
                  <p class="control">
                    <a
                      @click="openDrawer(drawerTypes.drawerSummary, account)"
                      :title="$t('General.View_Summary')"
                    >
                      <span class="icon">
                        <font-awesome-icon :icon="['fas', 'info-circle']" />
                      </span>
                    </a>
                  </p>
                  <p class="control">
                    <a
                      @click="openModal(modalTypes.modalAccountRights, account)"
                      :title="$t('Form.Control.Edit')"
                    >
                      <span class="icon">
                        <font-awesome-icon :icon="['fas', 'edit']" />
                      </span>
                    </a>
                  </p>

                  <p class="control">
                    <a
                      @click="openModal(modalTypes.modalDelete, account)"
                      :title="$t('Form.Control.Delete')"
                      class="has-text-danger"
                    >
                      <span class="icon">
                        <font-awesome-icon :icon="['fas', 'trash-alt']" />
                      </span>
                    </a>
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </transition>
      </table>

      <pagination
        v-if="morePages > 0"
        :currentPage="page"
        :morePages="morePages"
        @pageSelected="pageSelected"
      ></pagination>
    </div>
  </div>
</template>

<script>
import AccountProvider from '@/providers/account'
import { mapState, mapGetters } from 'vuex'
// const Message = () =>
//   import(/* webpackChunkName: "settings-accounts" */ '@/components/UI/Message')
const Pagination = () =>
  import(
    /* webpackChunkName: "settings-accounts" */ '@/components/UI/Pagination'
  )
const DrawerAccountSummary = () =>
  import(
    /* webpackChunkName: "settings-accounts" */ '@/components/Accounts/DrawerAccountSummary'
  )
const ModalAddAccount = () =>
  import(
    /* webpackChunkName: "settings-accounts" */ '@/components/Accounts/ModalAddAccount'
  )
const ModalEditAccountRights = () =>
  import(
    /* webpackChunkName: "settings-accounts" */ '@/components/Accounts/ModalEditAccountRights'
  )
const ModalDeleteAccount = () =>
  import(
    /* webpackChunkName: "settings-accounts" */ '@/components/Accounts/ModalDeleteAccount'
  )
const TextPlaceholder = () =>
  import(
    /* webpackChunkName: "settings-accounts" */ '@/components/UI/TextPlaceholder'
  )

export default {
  name: 'account-list',

  components: {
    //Message,
    DrawerAccountSummary,
    ModalAddAccount,
    ModalEditAccountRights,
    ModalDeleteAccount,
    Pagination,
    TextPlaceholder,
  },

  data() {
    return {
      locationId: Number(this.$route.params.locationId),
      page: 1,
      itemsPerPage: 15,
      morePages: 0,
      reachedEnd: false,
      searchTerm: '',
      isLoading: false,
      accounts: [],
      selectedAccount: null,
      modalType: '',
      modalTypes: {
        modalAdd: 'modalAdd',
        modalAccountRights: 'modalEditAccountRights',
        modalDelete: 'modalDelete',
      },
      drawerType: '',
      drawerTypes: {
        drawerSummary: 'drawerSummary',
      },
    }
  },

  computed: {
    ...mapState('profileStore', ['profile']),
    ...mapGetters('locationStore', ['accountRights']),
  },

  created() {
    this.getAccounts()
  },

  methods: {
    getAccounts() {
      let self = this
      this.isLoading = true

      AccountProvider.methods
        .getAllLocationAccounts(
          this.locationId,
          this.searchTerm,
          this.page,
          this.itemsPerPage
        )
        .then((response) => {
          if (response.status === 200) {
            self.morePages = response.data.MorePages
            self.accounts = response.data.Results
            if (self.page > 1 && !response.data.Results.length) {
              self.page = self.page - 1
              self.getAccounts()
            }
          }
        })
        .finally(() => {
          self.isLoading = false
        })
    },

    hideModal() {
      this.modalType = ''
    },

    isOwnAccount(account) {
      if (this.profile) {
        return account.ProfileId === this.profile.Id
      }
      return false
    },

    openModal(modalType, account) {
      this.modalType = modalType
      this.selectedAccount = account
    },

    openDrawer(drawerType, account) {
      this.drawerType = drawerType
      this.selectedAccount = account
    },
    hideDrawer() {
      this.drawerType = ''
    },

    pageSelected(page) {
      this.selectedAccount = null
      this.reachedEnd = false
      this.page = page
      this.getAccounts()
    },
  },
}
</script>

<style lang="scss" scoped>
th {
  &:not(:last-child) {
    width: 50%;
  }
}
</style>
